<template>
  <div class="login">
    <div class="logo">
      <img :src="require('@/assets/login/logo.png')" alt="" />
    </div>
    <div class="img-label">
      <img :src="require('@/assets/login/school_label_icon.png')" alt="" />
    </div>

    <div class="form">
      <div class="form-item">
        <img :src="require('@/assets/login/account_icon.png')" alt="" />
        <input
          type="text"
          placeholder="请输入准考证号"
          maxlength="50"
          v-model="formParam.examId"
        />
      </div>
      <div class="form-item">
        <img :src="require('@/assets/login/password_icon.png')" alt="" />
        <input
          placeholder="请输入手机号"
          v-model="formParam.mobile"
          type="tel"
          maxlength="11"
        />
      </div>
      <div class="error-msg">{{ errorMsg }}</div>
      <div class="submit-btn" @click="submit">
        <img :src="require('@/assets/login/login_btn.png')" />
        <span class="label">查询成绩</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data() {
    return {
      showError: false,
      errorMsg: '',
      formParam: {
        examId: '',
        mobile: ''
      }
    }
  },

  mounted() {
    this.checkSessionFormParam()
  },

  methods: {
    checkSessionFormParam() {
      let formParam = this.$util.getSession('formParam')
      if (formParam) {
        this.formParam = formParam
      }
    },
    submit() {
      this.$ajax.get('/getScore', this.formParam).then(res => {
        if (res.code === 200) {
          this.$util.setSession('formParam', this.formParam)
          this.$router.push('/home')
        } else {
          this.showError = true
          this.errorMsg = res.msg
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  background: url('~@/assets/login/login_bg.png');
  text-align: center;

  .logo {
    padding-top: 0.27rem;
    img {
      width: 0.56rem;
      height: 0.56rem;
    }
  }

  .img-label {
    margin-top: 0.1rem;
    img {
      width: 2.81rem;
      height: 0.58rem;
    }
  }

  .form {
    margin: 0 auto;
    margin-top: 0.38rem;
    background: #ffffff;
    border-radius: 0.05rem;
    width: 3.35rem;
    text-align: center;
    padding: 0.24rem 0 0.25rem 0rem;

    .form-item {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 0.3rem;
      width: 2.75rem;
      height: 0.6rem;
      margin-left: auto;
      margin-right: auto;

      background: #f1f3f8;
      border-radius: 0.05rem;
      input {
        border: none;
        background: #f1f3f8;
        font-size: 0.14rem;
        color: #4f6173;
      }

      img {
        margin-left: 0.1rem;
        width: 0.2rem;
        height: 0.2rem;
      }
    }

    .error-msg {
      width: 1.54rem;
      color: #eb6841;
      margin-top: 0.08rem;
      margin-left: auto;
      margin-right: auto;
    }

    .submit-btn {
      position: relative;
      margin-top: 0.2rem;
      img {
        width: 2.7rem;
        height: 0.7rem;
      }
      .label {
        position: absolute;
        font-size: 0.2rem;
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
